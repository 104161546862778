import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoImages from "../components/logoImages"
import './skills.css'

const Skills = () => {

  const defaultButtonColor = '#337ab7'
  const activeButtonColor = '#31b0d5'  

  const filterCategories = (category) => {
    const buttons = document.getElementById('categories_buttons_container').getElementsByTagName('button')
    for (let button of buttons) {
      if (button.id === category) {
        button.style.backgroundColor = activeButtonColor
      } else {
        button.style.backgroundColor = defaultButtonColor
      }
    }
    const skills = document.getElementsByClassName('skillContainer')
    if (category === 'All') {
      for (let skill of skills) {
        skill.style.display = 'block'
      }
    } else {
      for (let skill of skills) {
        if (!skill.classList.contains(category)) {
          skill.style.display = 'none'
        } else {
          skill.style.display = 'block'
        }
      }
    }
    const main = document.getElementsByTagName('main')[0]
    main.dispatchEvent(new Event('resize', { 'bubbles': true }))
  }

  return (
  <Layout pageTitle="Skills">
    <SEO title="Skills" />
    <h1>Skills</h1>
    <br></br>
    <div id="categories_buttons_container" style={{ textAlign: 'center', marginBottom: '20px', right: 0 }}>
      <button id="All" style={{ backgroundColor: activeButtonColor }} onClick={ () => filterCategories('All') }>All</button>
      <button id="WebDevFrontend" onClick={ () => filterCategories('WebDevFrontend') }>Web Dev Frontend</button>
      <button id="WebDevBackend" onClick={ () => filterCategories('WebDevBackend') }>Web Dev Backend</button>
      <button id="Data" onClick={ () => filterCategories('Data') }>Data</button>
      <button id="DevOps" onClick={ () => filterCategories('DevOps') }>DevOps</button>
      <button id="Graphics" onClick={ () => filterCategories('Graphics') }>Graphics</button>
      <button id="Audio" onClick={ () => filterCategories('Audio') }>Audio</button>
      <button id="Various" onClick={ () => filterCategories('Various') }>Various</button>
    </div>  
    <LogoImages></LogoImages>
  </Layout>
  )  
}

export default Skills
